import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SuggestionType } from '../../../../clients/CorrectoApiClient'
import { PremiumSuggestionsDetectedInfoBanner } from '../PremiumSuggestionsDetectedInfoBanner/PremiumSuggestionsDetectedInfoBanner'
import { SuggestionCard } from '../SuggestionCard/SuggestionCard'
import { PremiumUpsell } from '../PremiumUpsell/PremiumUpsell'
import { CharactersLimitWarning } from '../CharactersLimitWarning/CharactersLimitWarning'
import { EmptyStateNoContent } from '../EmptyStateNoContent/EmptyStateNoContent'
import { EmptyStateNoSuggestions } from '../EmptyStateNoSuggestions/EmptyStateNoSuggestions'
import { SuggestionPannelHeader } from '../SuggestionPannelHeader/SuggestionPannelHeader'
import { MIN_CHARACTERS_REPHRASING } from '../../../../utils/constants'
import { SuggestionCardSkeleton } from '../SuggestionCardSkeleton/SuggestionCardSkeleton'
import { type ScoringPreviewPanelProps } from '../ScoringPreviewPanel/ScoringPreviewPanel'

interface ComponentProps extends ScoringPreviewPanelProps {
  isUserPremium?: boolean
  suggestions: any[] | null | undefined
  documentContentLength: number
  checkLimited: boolean
  isAdvancedSuggestionsInfoAcknowledged: boolean
  getRephraseWritingSuggestions: ({
    selected_text,
  }: {
    selected_text?: string
  }) => void
  activeSuggestion: string
  isLoadingRephraseSuggestions: boolean
  isFirstRephraseSuggestionsRetrieval: boolean
  onSuggestionSelectHandler: (
    replacement: string,
    suggestionIndex: number,
  ) => void
  setActiveSuggestion: (index: string) => void
  onRephraseSuggestionDiscardHandler: (suggestionIndex: number) => void
  onRephraseSuggestionSelectHandler: (
    replacement: string,
    suggestionIndex: number,
    id: string,
  ) => void
  getMap: () => Map<number, HTMLElement>
  createDocument: () => void
}

export const SuggestionPannel: FC<ComponentProps> = ({
  activeSuggestion,
  checkLimited,
  createDocument,
  documentContentLength,
  getMap,
  getRephraseWritingSuggestions,
  isAdvancedSuggestionsInfoAcknowledged,
  isFirstRephraseSuggestionsRetrieval,
  isLoadingRephraseSuggestions,
  isUserPremium,
  onRephraseSuggestionDiscardHandler,
  onRephraseSuggestionSelectHandler,
  onSuggestionSelectHandler,
  overallScore,
  overallScoreLoading,
  scores,
  scoresLoading,
  setActiveSuggestion,
  suggestions,
}) => {
  const { t } = useTranslation()

  const suggestionsCount = suggestions?.length ?? 0
  const hasMinimumCharacters = documentContentLength > MIN_CHARACTERS_REPHRASING

  const showGetMoreRephraseSuggestionsButton =
    hasMinimumCharacters &&
    !isFirstRephraseSuggestionsRetrieval &&
    !isLoadingRephraseSuggestions

  const showPremiumUpsell = !isUserPremium && hasMinimumCharacters

  const showEmptyStateNoSuggestions =
    !suggestions?.length && !hasMinimumCharacters

  return (
    <div className="bg-white h-min w-100 rounded-lg grow overflow-y-auto">
      <SuggestionPannelHeader
        documentLength={documentContentLength}
        suggestionCount={suggestionsCount}
        overallScore={overallScore}
        overallScoreLoading={overallScoreLoading}
        scores={scores}
        scoresLoading={scoresLoading}
        getRephraseWritingSuggestions={getRephraseWritingSuggestions}
        showGetMoreRephraseSuggestionsButton={
          showGetMoreRephraseSuggestionsButton
        }
      />
      {documentContentLength === 0 ? (
        <EmptyStateNoContent />
      ) : (
        <>
          {showEmptyStateNoSuggestions ? (
            <EmptyStateNoSuggestions />
          ) : (
            <div className="flex flex-col">
              <div className="shadow-box">
                {checkLimited ? (
                  <CharactersLimitWarning createDocument={createDocument} />
                ) : null}
                {!isAdvancedSuggestionsInfoAcknowledged &&
                !isFirstRephraseSuggestionsRetrieval ? (
                  <PremiumSuggestionsDetectedInfoBanner />
                ) : null}
              </div>
              <div className="flex flex-col p-4 gap-4 h-auto overflow-x-scroll">
                {isLoadingRephraseSuggestions ? (
                  <SuggestionCardSkeleton />
                ) : null}
                {suggestions?.map((match, i) => {
                  return match.type.typeName !==
                    SuggestionType.RephraseSuggestion ? (
                    <SuggestionCard
                      active={
                        match.id === activeSuggestion ||
                        (i === 0 && activeSuggestion === '')
                      }
                      content={match.context}
                      key={match.id}
                      onClick={() => {
                        setActiveSuggestion(match.id)
                      }}
                      onSuggestionSelect={replacement => {
                        onSuggestionSelectHandler(replacement, i)
                      }}
                      ref={node => {
                        const map = getMap()
                        if (node) {
                          map.set(i, node)
                        } else {
                          map.delete(i)
                        }
                      }}
                      replacements={match.replacements}
                      title={match.shortMessage || match.message}
                      type={match.type}
                      variant="primary"
                      issueType={match.rule?.issueType}
                    />
                  ) : (
                    <SuggestionCard
                      active={
                        match.id === activeSuggestion ||
                        (i === 0 && activeSuggestion === '')
                      }
                      content={match.context}
                      key={match.id}
                      onClick={() => {
                        setActiveSuggestion(match.id)
                      }}
                      onSuggestionDiscard={() => {
                        onRephraseSuggestionDiscardHandler(i)
                      }}
                      onSuggestionSelect={replacement => {
                        onRephraseSuggestionSelectHandler(
                          replacement,
                          i,
                          match.id,
                        )
                      }}
                      ref={node => {
                        const map = getMap()
                        if (node) {
                          map.set(i, node)
                        } else {
                          map.delete(i)
                        }
                      }}
                      replacements={[{ value: match.paraphrase_alternative }]}
                      title={t('Sugerencia de reescritura')}
                      type={match.type}
                      variant="secondary"
                      issueType={match.rule?.issueType}
                    />
                  )
                })}
                {showPremiumUpsell ? (
                  <PremiumUpsell
                    className="pt-6 px-0"
                    separator={suggestionsCount > 0}
                  />
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
