import type { FC } from 'react'
import { Typography } from '../Typography/Typography'
import { IconButton } from '../IconButton/IconButton'
import { Button } from '../Button/Button'
import vector from '../assets/vector.svg'
import type { IlustrationName } from '../Ilustration/Ilustration'
import { Ilustration } from '../Ilustration/Ilustration'

interface BannerProps {
  title: string
  description: string
  buttonText: string
  onClick: () => void
  onClose: () => void
  ilustration: IlustrationName
}

export const Banner: FC<BannerProps> = ({
  description,
  title,
  buttonText,
  onClick,
  onClose,
  ilustration,
}) => {
  return (
    <div className="bg-[right_46px_top_8px] top py-8 pl-8 pr-14 bg-white bg-no-repeat rounded-lg shadow-2 w-full  border border-none relative overflow-hidden">
      <div className="absolute top-[8px] right-[46px]">
        <img alt="vector " src={vector} />
      </div>
      <div className="grid grid-cols-3">
        <div className="col-span-2 flex items-center">
          <span className="pr-1">
            <Ilustration ilustration={ilustration} />
          </span>
          <div>
            <Typography
              className="text-blue-correcto-800 text-ellipsis overflow-hidden whitespace-nowrap font-bold"
              component="p"
              variant="body1"
            >
              {title}
            </Typography>
            <Typography
              className="text-blue-correcto-800 text-ellipsis overflow-hidden whitespace-nowrap"
              component="p"
              variant="small"
            >
              {description}
            </Typography>
          </div>
        </div>
        <div className="flex items-center justify-end relative">
          <Button
            label={buttonText}
            leftIcon="brand-chrome"
            onClick={onClick}
            size="large"
            variant="primary"
          />
        </div>
        <IconButton
          className="top-2 right-2 absolute"
          icon="close"
          onClick={onClose}
          variant="neutral"
        />
      </div>
    </div>
  )
}
