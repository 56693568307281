import { useState, useEffect, type FC } from 'react'
import { clsx } from 'clsx'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'
import { Typography } from '../Typography/Typography'

export enum ToastType {
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
}

export interface ToastProps {
  type?: ToastType
  text?: string
  timeout?: number
  onClose: () => void
}

export const Toast: FC<ToastProps> = ({ type, text, timeout, onClose }) => {
  const [isEntering, setIsEntering] = useState(true)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsEntering(false)
    })
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (timeout && timeout > 0) {
      timer = setTimeout(() => {
        setIsClosing(true)
      }, timeout)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [timeout])

  useEffect(() => {
    if (isClosing) {
      setTimeout(onClose, 300)
    }
  }, [isClosing, onClose])

  let icon: IconName
  switch (type) {
    case ToastType.Success:
      icon = 'check'
      break
    case ToastType.Danger:
      icon = 'error-circle'
      break
    default:
      icon = 'alert-circle'
  }

  const divClass = clsx('c-toast', {
    'bg-neutral-100 text-neutral-800 fill-neutral-800': type === ToastType.Info,
    'bg-green-checker-200 text-green-checker-800 fill-green-checker-800':
      type === ToastType.Success,
    'bg-orange-generative-200 text-orange-generative-800 fill-orange-generative-800':
      type === ToastType.Warning,
    'bg-red-extension-100 text-red-extension-800 fill-red-extension-800':
      type === ToastType.Danger,
    'c-toast-enter': isEntering,
    'c-toast-enter-active': !isEntering,
    'c-toast-exit': isClosing,
    'c-toast-exit-active': isClosing,
  })

  const handleCloseClick = () => {
    setIsClosing(true)
  }

  return (
    <div className={divClass}>
      <div className="toast-container">
        <span className="toast-left-icon">
          <Icon icon={icon} size="md" />
        </span>
        <Typography className="font-normal" variant="body2">
          {text}
        </Typography>
        <button
          className="toast-right-icon"
          onClick={handleCloseClick}
          type="button"
        >
          <Icon icon="close" size="sm" />
        </button>
      </div>
    </div>
  )
}
