import { ToastType } from 'ui'
import { useTranslation } from 'react-i18next'
import { useToast } from '../contexts'

type ApiResponse = {
  status: number;
  body: ApiResponseBody;
}

interface ApiResponseBody {
  error?: ApiResponseError;
  code?: string;
  [key: string]: any;
}

interface ApiResponseError {
  status: number;
  error: string;
  details: string;
}

const ERROR_MESSAGES: {[key: string]: string} = {
  'User already exists': 'Hay una cuenta registrada con este correo electrónico.',
  'This password is too short. It must contain at least 8 characters.': 'La contraseña es demasiado corta. Debe contener al menos 8 caracteres.',
  'password: This password is too short. It must contain at least 8 characters.': 'La contraseña es demasiado corta. Debe contener al menos 8 caracteres.',
  'This password is too common.': 'Esta contraseña es demasiado común.',
  'password: This password is too common.': 'Esta contraseña es demasiado común.',
  'This password is entirely numeric.': 'Esta contraseña es completamente numérica. Añade al menos una letra.',
  'password: This password is entirely numeric.': 'Esta contraseña es completamente numérica. Añade al menos una letra.',
  'Ignore Word already exists': 'La palabra que intentas añadir ya existe en tu vocabulario.',
  "We couldn't find an account associated with that email. Please try a different e-mail address.": 'No pudimos encontrar una cuenta asociada con este email. Pruebe con un email diferente.',
  "email: We couldn't find an account associated with that email. Please try a different e-mail address.": 'No pudimos encontrar una cuenta asociada con este email. Pruebe con un email diferente.',
  'The password is too similar to the username.': 'La contraseña es demasiado similar al nombre de usuario. Pruebe con una contraseña diferente.',
  'password: The password is too similar to the username.': 'La contraseña es demasiado similar al nombre de usuario. Pruebe con una contraseña diferente.',
};

export const useCommonApiErrorMessage = () => {
  const { t } = useTranslation()
  const toastManager = useToast()

  const translatedErrorMessages = Object.fromEntries(
    Object.entries(ERROR_MESSAGES).map(([key, value]) => [key, t(value)])
  );

  function getErrorMessage(key: string): string {
    return translatedErrorMessages[key] || '';
  }

  function showToastAndThrowError(response: ApiResponse, fallbackMessage: string) {
    toastManager.showToast(ToastType.Danger, fallbackMessage)
    throw new Error(JSON.stringify(response))
  }

  const checkForCommonErrorMessage = (
    response: ApiResponse,
    fallbackMessage: string,
  ) => {
    if (response.status === 401 && response.body.code === 'token_not_valid') {
      toastManager.showToast(ToastType.Danger, t('Tu session ha expirado. Por favor, inicia sesión de nuevo.'))
    }else if (response.status === 400) {
      const errorObject = response.body.error;
      const message = errorObject && errorObject.error ? getErrorMessage(errorObject.error) : '';
      message !== '' ? toastManager.showToast(ToastType.Danger, message) : showToastAndThrowError(response, fallbackMessage);
    }else{
      showToastAndThrowError(response, fallbackMessage);
    }
  }

  return { checkForCommonErrorMessage }
}
