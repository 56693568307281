import * as amplitude from '@amplitude/analytics-browser'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Input, Typography } from 'ui'
import { ToastType } from 'ui/src/Toast/Toast'
import { useTranslation } from 'react-i18next'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { useWindowSize } from '@uidotdev/usehooks'
import { HeaderSubtitle, PageHeader, PublicPage } from '../../Components'
import { EMAIL_REGEX_PATTERN } from '../../../utils/constants'
import { useUser, useToast, useOauth } from '../../../contexts'
import { TermsAndConditions } from '../../Components/TermsAndConditions/TermsAndConditions'
import { trackUserAction } from '../../../utils/amplitude'
import {
  AuthType,
  LOCAL_STORAGE_AUTH_TYPE,
} from '../../../clients/CorrectoApiClient'

export const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const userManager = useUser()
  const { showToast } = useToast()
  const oauth = useOauth()

  const { t } = useTranslation()

  const goToRememberPassword = () => {
    navigate('/reset-password')
  }

  const logInHandler = () => {
    userManager
      .login(email, password)
      .then(response => {
        if (response.ok) {
          amplitude.setUserId(response.body.user.uuid)
          trackUserAction({
            name: 'Sign in Email',
          })

          localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Basic)
        } else {
          throw new Error(JSON.stringify(response))
        }

        const { next }: { next: string } = location.state || {
          next: '/projects',
        }

        navigate(next)
      })
      .catch(() => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_TYPE)

        showToast(
          ToastType.Danger,
          t('La autenticación ha fallado. Inténtalo de nuevo.'),
        )
      })
  }

  const googleSignInHandler = () => {
    oauth.oauthSignIn('web', 'LOGIN')
    localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Google)
  }

  return (
    <PublicPage>
      <PageHeader
        subtitle={
          <HeaderSubtitle
            firstLine={t(
              'Bienvenido de nuevo. Por favor: introduce tus datos de usuario.',
            )}
            secondLine={<LoginSecondLine />}
          />
        }
        title={t('Iniciar sesión')}
      />

      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Input
            label={t('E-mail *')}
            onChange={e => {
              setEmail(e.target.value)
            }}
            placeholder={t('Escribe tu e-mail')}
            validate={() =>
              email.length > 1
                ? {
                    valid: new RegExp(EMAIL_REGEX_PATTERN).test(email),
                    message: t('El formato del e-mail no es válido'),
                  }
                : {
                    valid: false,
                    message: t('El e-mail es obligatorio'),
                  }
            }
            value={email}
          />
          <Input
            label={t('Contraseña *')}
            mode="password"
            onChange={e => {
              setPassword(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setPassword(e.currentTarget.value)
                logInHandler()
              }
            }}
            placeholder={t('Escribe tu contraseña')}
            requiredMessage={t('La contraseña es obligatoria')}
            value={password}
          />
          <Button
            className="mt-[-3px] mb-[-1px]"
            label={t('Acceder')}
            onClick={logInHandler}
          />
          <Button
            label={t('¿Has olvidado tu contraseña?')}
            onClick={goToRememberPassword}
            size="small"
            variant="tertiary"
          />
        </div>
        <div className="flex flex-col gap-[16px] border-t border-neutral-500 pt-[24px]">
          <Button
            label={t('Accede con Google')}
            leftIcon="google"
            onClick={googleSignInHandler}
            variant="secondary"
          />
        </div>
        <TermsAndConditions />
      </div>
    </PublicPage>
  )
}

const LoginSecondLine: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowSize()

  const isSm = useMemo(() => {
    return (width && width < 768) as boolean
  }, [width])

  return (
    <>
      <Button
        className="py-0 pl-0 pr-0 underline"
        label={t('Accede al registro')}
        onClick={() => {
          navigate('/sign-up')
        }}
        size={isSm ? 'small' : undefined}
        variant="link"
      />
      <Typography
        className="text-blue-correcto-700 font-normal pl-1"
        variant={isSm ? 'small' : 'body2'}
      >
        {t('si todavía no tiene una cuenta.')}
      </Typography>
    </>
  )
}
