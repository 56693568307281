import { Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import {
  Login,
  ResetPassword,
  ResetPasswordEmailSent,
  NewPassword,
  NewPasswordSuccess,
  SignUp,
  AccountActivation,
  AccountActivationCode,
  OauthGoogle,
  DesktopExperience,
} from './Login'
import { Projects } from './Projects/Projects/Projects'
import { PrivateRoute, PublicRoute } from './Components'
import { Dictionary } from './Dictionary/Dictionary/Dictionary'
import { Settings } from './Settings/Settings/Settings'
import { EditProject } from './Projects/EditProject/EditProject'
import { PublicPage } from './Login/Components'
import { Premium } from './Premium/Premium/Premium'
import { CheckoutSuccess } from './Premium/CheckoutSuccess/CheckoutSuccess'
import { CheckoutCanceled } from './Premium/CheckoutCanceled/CheckoutCanceled'

const App = () => {
  return (
    <Suspense fallback={<PublicPage />}>
      <Routes>
        <Route
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
          path="/sign-up"
        />
        <Route
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
          path="/sign-up-chrome-extension"
        />
        <Route
          element={
            <PublicRoute>
              <DesktopExperience />
            </PublicRoute>
          }
          path="/desktop-experience"
        />
        <Route
          element={
            <PublicRoute>
              <AccountActivation />
            </PublicRoute>
          }
          path="/account-activation"
        />
        <Route
          element={
            <PublicRoute>
              <AccountActivationCode />
            </PublicRoute>
          }
          path="/account-activation-code"
        />
        <Route
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
          path="/login"
        />
        <Route
          element={
            <PublicRoute>
              <OauthGoogle />
            </PublicRoute>
          }
          path="/oauth-google"
        />
        <Route
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
          path="/reset-password"
        />
        <Route
          element={
            <PublicRoute>
              <ResetPasswordEmailSent />
            </PublicRoute>
          }
          path="/reset-password-email-sent"
        />
        <Route
          element={
            <PublicRoute>
              <NewPassword />
            </PublicRoute>
          }
          path="/new-password"
        />
        <Route
          element={
            <PublicRoute>
              <NewPasswordSuccess />
            </PublicRoute>
          }
          path="/new-password-success"
        />
        <Route
          element={
            <PrivateRoute>
              <EditProject />
            </PrivateRoute>
          }
          path="/projects/:projectUuid"
        />
        <Route
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
          path="/projects"
        />
        <Route
          element={
            <PrivateRoute>
              <Dictionary />
            </PrivateRoute>
          }
          path="/dictionary"
        />
        <Route
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
          path="/settings"
        />
        <Route
          element={
            <PrivateRoute>
              <Premium />
            </PrivateRoute>
          }
          path="/premium"
        />
        <Route
          element={
            <PrivateRoute>
              <CheckoutSuccess />
            </PrivateRoute>
          }
          path="/checkouts/success"
        />
        <Route
          element={
            <PrivateRoute>
              <CheckoutCanceled />
            </PrivateRoute>
          }
          path="/checkouts/canceled"
        />
        <Route element={<Navigate to="/login" />} path="*" />
      </Routes>
    </Suspense>
  )
}

export default App
