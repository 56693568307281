import { clsx } from 'clsx'
import { useTranslation } from 'react-i18next'
import { Button } from 'ui'

export const GetMoreRephraseSuggestionsButton = ({
  onClick,
  className,
}: {
  onClick: () => void
  className?: string
}) => {
  const { t } = useTranslation()

  return (
    <div className={clsx('flex justify-center pb-4 px-7.5', className)}>
      <Button
        className="w-fit self-center"
        label={t('Generar nuevas sugerencias avanzadas')}
        leftIcon="wand"
        onClick={onClick}
        size="small"
        variant="secondary"
      />
    </div>
  )
}
