import { useRef, useState, type FC } from 'react'
import { GraphicModal, InteractiveModal, SimpleModal, useModal } from 'ui'
import { clsx } from 'clsx'
import useOnClickOutside from './useOnClickOutside'

export const ModalContainer: FC = () => {
  const modalManager = useModal()
  const modalRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(modalRef, () => {
    modalManager.closeModal()
  })

  const [closing, setClosing] = useState(false)

  const delayedCloseModal = (fn?: () => void) => () => {
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      modalManager.closeModal()
      fn?.()
    }, 280)
  }

  const modalClassName = clsx({
    'animate-slide-down-out': closing,
    'animate-slide-down-in': !closing && modalManager.isOpen,
  })

  if (!modalManager.isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div ref={modalRef}>
        {modalManager.modalProps.type === 'simple' && (
          <SimpleModal
            className={modalClassName}
            onCancelClick={delayedCloseModal(
              modalManager.modalProps.onSecondaryClick,
            )}
            onClose={delayedCloseModal(modalManager.modalProps.onClose)}
            onPrimaryClick={delayedCloseModal(
              modalManager.modalProps.onPrimaryClick,
            )}
            primaryAction={modalManager.modalProps.primaryAction}
            primaryActionType={modalManager.modalProps.primaryActionType}
            title={modalManager.modalProps.title}
          >
            {modalManager.modalProps.body}
          </SimpleModal>
        )}
        {modalManager.modalProps.type === 'graphic' && (
          <GraphicModal
            className={modalClassName}
            ilustration={modalManager.modalProps.ilustration}
            onClose={delayedCloseModal(modalManager.modalProps.onClose)}
            onPrimaryClick={delayedCloseModal(
              modalManager.modalProps.onPrimaryClick,
            )}
            onSecondaryClick={delayedCloseModal(
              modalManager.modalProps.onSecondaryClick,
            )}
            primaryAction={modalManager.modalProps.primaryAction}
            primaryActionType={modalManager.modalProps.primaryActionType}
            primaryIcon={modalManager.modalProps.primaryIcon}
            secondaryAction={modalManager.modalProps.secondaryAction}
            textAlign={modalManager.modalProps.textAlign}
            title={modalManager.modalProps.title}
            titleAlign={modalManager.modalProps.titleAlign}
          >
            {modalManager.modalProps.body}
          </GraphicModal>
        )}
        {modalManager.modalProps.type === 'interactive' && (
          <InteractiveModal
            className={modalClassName}
            onCancelClick={delayedCloseModal(
              modalManager.modalProps.onSecondaryClick,
            )}
            onClose={delayedCloseModal(modalManager.modalProps.onClose)}
            onPrimaryClick={delayedCloseModal(
              modalManager.modalProps.onPrimaryClick,
            )}
            primaryAction={modalManager.modalProps.primaryAction}
            primaryActionType={modalManager.modalProps.primaryActionType}
            secondaryAction={modalManager.modalProps.secondaryAction}
            subtitle={modalManager.modalProps.subtitle}
            title={modalManager.modalProps.title}
            titleIcon={modalManager.modalProps.titleIcon}
          >
            {modalManager.modalProps.body}
          </InteractiveModal>
        )}
      </div>
    </div>
  )
}
