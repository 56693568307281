import type { FC, InputHTMLAttributes } from 'react'
import { useState } from 'react'
import { clsx } from 'clsx'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'
import { Typography } from '../Typography/Typography'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  mode?: 'text' | 'password'
  label?: string
  placeholder?: string
  requiredMessage?: string
  disabled?: boolean
  leftIcon?: IconName
  rightIcon?: IconName
  unit?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  validate?: () => { valid: boolean; message: string }
}

export const Input: FC<InputProps> = ({
  mode = 'text',
  label,
  placeholder,
  requiredMessage,
  disabled = false,
  leftIcon,
  rightIcon,
  unit,
  onChange,
  onKeyDown,
  value,
  validate,
  ...props
}) => {
  const [hasError, setHasError] = useState(false)
  const [helperMessage, setHelperMessage] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const localValidate = (inputValue: string) => {
    if (validate) {
      const { valid, message } = validate()
      if (!valid) {
        setHasError(true)
        setHelperMessage(message)
        return
      }
    }
    let valid = true
    if (inputValue.length === 0 && requiredMessage) {
      setHasError(true)
      setHelperMessage(requiredMessage)
      valid = false
    }

    if (valid) {
      setHasError(false)
      setHelperMessage(null)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    localValidate(e.target.value)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
    if (!hasError) return
    localValidate(e.target.value)
  }

  const divClass = clsx('c-input flex flex-col', {
    invalid: hasError,
    disabled,
    password: mode === 'password',
    'has-label': label,
  })

  const iconClass = clsx('input-icon-container', {
    'left-icon': leftIcon && mode === 'text',
    'right-icon': rightIcon,
    unit,
  })

  const renderRightIcon = () => {
    if (mode === 'password') {
      return (
        <button
          className="input-right-icon"
          onClick={toggleShowPassword}
          type="button"
        >
          <Icon icon={showPassword ? 'eye-off' : 'eye-on'} size="sm" />
        </button>
      )
    } else if (rightIcon) {
      return (
        <span className="input-right-icon">
          <Icon icon={rightIcon} size="sm" />
        </span>
      )
    }
    return null
  }

  return (
    <div className={divClass}>
      {label ? (
        <Typography className="font-normal" component="label" variant="body2">
          {label}
        </Typography>
      ) : null}
      <div className={iconClass}>
        {leftIcon && mode === 'text' ? (
          <span className="input-left-icon">
            <Icon icon={leftIcon} size="sm" />
          </span>
        ) : null}
        <input
          {...props}
          disabled={disabled}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          type={mode === 'password' && !showPassword ? 'password' : 'text'}
          value={value}
        />
        {renderRightIcon()}
        {unit ? <span className="input-right-unit">{unit}</span> : null}
      </div>
      {hasError ? (
        <Typography
          className="text-danger font-medium"
          component="small"
          variant="small"
        >
          {helperMessage}
        </Typography>
      ) : null}
    </div>
  )
}
