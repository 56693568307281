import {
  type FC,
  type PropsWithChildren,
  useState,
  useCallback,
  useContext,
  createContext,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import type { ToastType } from 'ui'
import { Toast } from 'ui'

interface ToastContext {
  showToast: (type: ToastType, text: string, timeout?: number) => void
  hideToast: (id: string) => void
}

export const ToastContext = createContext<ToastContext | null>(null)

interface Toast {
  id: string
  type: ToastType
  text: string
  timeout?: number
}

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const showToast = useCallback(
    (type: ToastType, text: string, timeout?: number) => {
      setToasts(currentToasts => [
        ...currentToasts,
        { id: uuidv4(), type, text, timeout },
      ])
    },
    [],
  )

  const hideToast = useCallback((id: string) => {
    setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <div id="toast-root">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            onClose={() => {
              hideToast(toast.id)
            }}
            text={toast.text}
            timeout={toast.timeout ?? 5000}
            type={toast.type}
          />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export function useToast() {
  return useContext(ToastContext)!
}
