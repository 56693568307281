import { clsx } from 'clsx'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Typography } from 'ui'
import {
  type SuggestionContext,
  type SuggestionReplacement,
  type Type,
  SuggestionType,
} from '../../../../clients/CorrectoApiClient'
import {
  MetricsCategory,
  MetricsName,
  trackEvent,
} from '../../../../utils/amplitude'
import { MAX_SUGGESTIONS } from '../../../../utils/constants'
import { getIssueColorClassName } from '../RichTextEditor/Nodes/SuggestionNode/utils'

type SuggestionCardVariant = 'primary' | 'secondary'
interface SuggestionCardProps {
  title: string
  content: SuggestionContext
  variant: SuggestionCardVariant
  active?: boolean
  onClick?: () => void
  replacements: SuggestionReplacement[]
  onSuggestionSelect: (replacement: string) => void
  onSuggestionDiscard?: (replacement: string) => void
  type: Type
  issueType: string
}

export const SuggestionCard = forwardRef<HTMLElement, SuggestionCardProps>(
  function SuggestionCard(
    {
      title,
      content,
      active = false,
      variant,
      onClick,
      replacements,
      onSuggestionSelect,
      onSuggestionDiscard,
      type,
      issueType,
    },
    ref,
  ) {
    const suggestionWord = content.text.substring(
      content.offset,
      content.offset + content.length,
    )

    const highlightText =
      type.typeName !== SuggestionType.RephraseSuggestion
        ? suggestionWord
        : content.text

    return active ? (
      <ActiveCard
        content={content}
        onSuggestionDiscard={onSuggestionDiscard}
        onSuggestionSelect={onSuggestionSelect}
        ref={ref}
        replacements={replacements}
        title={title}
        type={type}
        variant={variant}
        issueType={issueType}
      />
    ) : (
      <Card
        content={highlightText}
        onClick={onClick}
        ref={ref}
        title={title}
        type={type}
        variant={variant}
        issueType={issueType}
      />
    )
  },
)

interface CardProps {
  title: string
  content: string
  variant: SuggestionCardVariant
  type: Type
  onClick?: () => void
  issueType: string
}

const Card = forwardRef<HTMLElement, CardProps>(function Card(
  { title, content, onClick, variant, type, issueType },
  ref,
) {
  const cardVariantClassName = clsx({
    'flex flex-col border rounded-lg h-18 p-4 gap-2': true,
    'border-neutral-200': variant === 'primary',
    'border-secondary border-1': variant === 'secondary',
  })

  const issueColor = getIssueColorClassName(issueType)
  let typographyClassName = clsx({
    'block font-medium': true,
    'text-ellipsis overflow-hidden text-nowrap quoted':
      type.typeName === SuggestionType.RephraseSuggestion,
  })
  typographyClassName +=
    type.typeName !== SuggestionType.RephraseSuggestion ? ` ${issueColor}` : ''

  const titleClassName = clsx({
    'text-blue-correcto-800 grow font-medium': true,
    'pl-2': type.typeName === SuggestionType.RephraseSuggestion,
  })

  return (
    <span onClick={onClick} onKeyDown={onClick} ref={ref} role="button">
      <div className={cardVariantClassName}>
        <div className="flex items-center">
          {type.typeName === SuggestionType.RephraseSuggestion && (
            <Icon icon="target" size="sm" />
          )}
          <Typography className={titleClassName} variant="small">
            {title}
          </Typography>
          <Icon icon="chevron-down" size="sm" />
        </div>
        <div>
          <p className="block leading-4">
            <Typography className={typographyClassName} variant="small">
              {content}
            </Typography>
          </p>
        </div>
      </div>
    </span>
  )
})

interface ActiveCardProps {
  title: string
  content: SuggestionContext
  replacements: SuggestionReplacement[]
  variant: SuggestionCardVariant
  type: Type
  onSuggestionSelect: (replacement: string) => void
  onSuggestionDiscard?: (replacement: string) => void
  issueType: string
}

export const ActiveCard = forwardRef<HTMLElement, ActiveCardProps>(
  function ActiveCard(
    {
      title,
      content,
      replacements,
      onSuggestionSelect,
      onSuggestionDiscard,
      variant,
      type,
      issueType,
    },
    ref,
  ) {
    // const toastManager = useToast()

    const { t } = useTranslation()

    const suggestionWord = content.text.substring(
      content.offset,
      content.offset + content.length,
    )
    const beforeText = content.text.substring(0, content.offset)
    const afterText = content.text.substring(
      content.offset + content.length,
      content.text.length,
    )

    const cardVariantClassName = {
      primary: 'border-neutral-500 border-2 shadow-md',
      secondary: 'border-secondary border-2 shadow-md shadow-[#0c1a66]/30',
    }

    const titleClassName = clsx({
      'text-blue-correcto-800 grow font-medium': true,
      'pl-2': type.typeName === SuggestionType.RephraseSuggestion,
    })

    const issueColor = getIssueColorClassName(issueType)

    return (
      <span ref={ref}>
        <div
          className={`flex flex-col border rounded-lg h-18 p-4 gap-2 shadow-3 ${cardVariantClassName[variant]}`}
        >
          <div className="flex items-center">
            {type.typeName === SuggestionType.RephraseSuggestion && (
              <Icon icon="target" size="sm" />
            )}
            <Typography className={titleClassName} variant="small">
              {title}
            </Typography>
          </div>
          {type.typeName !== SuggestionType.RephraseSuggestion && (
            <p className="block leading-4">
              <Typography
                className="text-black-700 font-medium"
                variant="small"
              >
                {beforeText}
              </Typography>
              <Typography
                className={`${issueColor} font-medium`}
                variant="small"
              >
                {suggestionWord}
              </Typography>
              <Typography
                className="text-black-700 font-medium"
                variant="small"
              >
                {afterText}
              </Typography>
            </p>
          )}

          {type.typeName === SuggestionType.RephraseSuggestion && (
            <>
              <Typography className="font-medium" variant="small">
                {content.text}
              </Typography>
              <Typography
                className="text-green-checker-700 font-semibold"
                variant="small"
              >
                {replacements[0].value}
              </Typography>
            </>
          )}

          <div className="flex justify-start flex-wrap gap-1">
            {type.typeName !== SuggestionType.RephraseSuggestion &&
              replacements.slice(0, MAX_SUGGESTIONS).map((replacement, i) => (
                <Button
                  key={i}
                  label={replacement.value}
                  onClick={() => {
                    onSuggestionSelect(replacement.value)
                  }}
                  size="small"
                  variant="suggestion"
                />
              ))}
            {type.typeName === SuggestionType.RephraseSuggestion && (
              <>
                <Button
                  label={t('Aplicar')}
                  onClick={() => {
                    onSuggestionSelect(replacements[0].value)
                  }}
                  size="small"
                  variant="suggestion"
                />
                <Button
                  label={t('Descartar')}
                  onClick={() => {
                    if (onSuggestionDiscard) {
                      onSuggestionDiscard(replacements[0].value)

                      trackEvent(MetricsCategory.SuggestionInteracted, {
                        name: MetricsName.RephraseDismiss,
                        place: 'Sidebar',
                        location: window.location.hostname,
                      })
                    }
                  }}
                  size="small"
                  variant="discard"
                />
              </>
            )}
            {/* <Button
              label={t('Descartar')}
              onClick={rejectHandler}
              size="small"
              variant="discard"
            /> */}
          </div>
        </div>
      </span>
    )
  },
)
