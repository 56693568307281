const BASE_API_URL: string = import.meta.env.VITE_BASE_API_URL
const DJANGO_OAUTH_CLIENT_ID = import.meta.env.VITE_DJANGO_OAUTH_CLIENT_ID
const DJANGO_OAUTH_CLIENT_SECRET = import.meta.env
  .VITE_DJANGO_OAUTH_CLIENT_SECRET
const APP_HOST = import.meta.env.VITE_APP_HOST

export enum AuthType {
  Google = 'google',
  Basic = 'basic',
}

export const LOCAL_STORAGE_AUTH_TYPE = 'auth_type'

interface StripePriceRecurring {
  interval: string
  usage_type: string
  interval_count: number
  aggregate_usage: string
  trial_period_days: number
}

export interface StripePrice {
  id: string
  created: string
  currency: string
  recurring: StripePriceRecurring
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface StripeProduct {
  id: string
  created: string
  name: string
  default_price: StripePrice
  prices: StripePrice[]
}

interface Language {
  name: string
  code: string
  detectedLanguage?: {
    name: string
    code: string
    confidence: number
    source: string
  }
}

export interface SuggestionReplacement {
  value: string
}

export interface SuggestionContext {
  text: string
  offset: number
  length: number
}

export const SuggestionType = {
  TypingSuggestion: 'typing_suggestion',
  RephraseSuggestion: 'rephrase_suggestion',
} as const

export interface Type {
  typeName: (typeof SuggestionType)[keyof typeof SuggestionType]
}

interface Rule {
  id: string
  description: string
  issueType: string
  category: {
    id: string
    name: string
  }
}

export interface Match {
  message: string
  shortMessage: string
  replacements: SuggestionReplacement[]
  offset: number
  length: number
  context: SuggestionContext
  sentence: string
  type: Type
  rule: Rule
  ignoreForIncompleteSentence: boolean
  contextForSureMatch: number
  id: string
}

export interface TypingAssistantSuggestions {
  language: Language
  matches: Match[]
  check_limited: boolean
  score: number
}

export interface RephraseSuggestion {
  main_original_sentence: string
  paraphrase_alternative: string
  offset: number
  length: number
}

export interface RephraseWritingAssistantSuggestions {
  text: string
  suggestions: RephraseSuggestion[]
  rephrase_limited: boolean
}

interface RequestOptions {
  query: string
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  headers: HeadersInit | undefined
  body: any
}

export interface User {
  uuid: string
  email: string
  username: string
  name: string
  lastname: string
  confirmed: boolean
  accept_terms: boolean
  number_of_documents: number
  is_premium: boolean
  created: string
  errors?: string
  password?: string[]
}

export interface Auth {
  access_token: string
  refresh_token: string
  user: User
}

interface Paginated<T> {
  count: number
  next: string
  previous: string
  results: T[]
}

export interface Document {
  uuid: string
  created: string
  modified: string
  title: string
  content: string
  content_formats: object
}
export interface Response<T> {
  ok: boolean
  status: number
  region: string
  body: T
}

interface Score {
  score: number
  display_text: Record<string, string>
}

export type Scores = Record<
  'comprehension' | 'readability' | 'difficulty' | 'clarity',
  Score
>

export interface DictionaryWord {
  uuid: string
  created: string
  modified: string
  ignore_word: string
}

export class CorrectoApiClient {
  base_url: string

  constructor() {
    this.base_url = `${BASE_API_URL}/api`

    window.addEventListener(
      'message',
      (event: MessageEvent<{ payload: string; type: string }>) => {
        if (event.origin !== APP_HOST) return
        if (event.source !== window) return
        if (event.data.type && event.data.type === 'FROM_EXTENSION') {
          const extId = event.data.payload
          localStorage.setItem('extId', extId)
        }
      },
      false,
    )
  }

  async request(options: RequestOptions) {
    let query = new URLSearchParams(options.query || {}).toString()
    if (query !== '') {
      query = `?${query}`
    }

    let response
    try {
      const authToken = localStorage.getItem('accessToken')

      const headers = new Headers({
        'Content-Type': 'application/json',
        ...options.headers,
      })

      if (authToken) {
        headers.set('Authorization', `Bearer ${authToken}`)
      }

      response = await fetch(this.base_url + options.url + query, {
        method: options.method,
        headers,
        body: options.body ? JSON.stringify(options.body) : null,
      })
    } catch (error: unknown) {
      response = {
        ok: false,
        status: 500,
        json: async () => {
          return Promise.resolve({
            code: 500,
            message: 'The server is unresponsive',
            description: (error as Error).toString(),
          })
        },
      }
    }

    if (!response.ok && response.status === 401) {
      this.logout()
    }

    return {
      ok: response.ok,
      status: response.status,
      region: response.headers?.get('x-client-region') ?? '',
      body: ![204, 404, 422].includes(response.status)
        ? await response.json()
        : null,
    }
  }

  async get(url: any, query: any, options: any) {
    return this.request({
      method: 'GET',
      url,
      query,
      ...options,
    } as RequestOptions)
  }

  async post(url: any, body: any, options: any) {
    return this.request({
      method: 'POST',
      url,
      body,
      ...options,
    } as RequestOptions)
  }

  async put(url: any, body: any, options: any) {
    return this.request({
      method: 'PUT',
      url,
      body,
      ...options,
    } as RequestOptions)
  }

  async delete(url: any, options: any) {
    return this.request({ method: 'DELETE', url, ...options } as RequestOptions)
  }

  async login(username: string, password: string) {
    const response: Response<Auth> = await this.post(
      '/rest/1_0/auth/login/',
      {
        username,
        password,
      },
      {},
    )
    if (response.ok) {
      localStorage.setItem('accessToken', response.body.access_token)
      localStorage.setItem('refreshToken', response.body.refresh_token)
      localStorage.setItem('userUuid', response.body.user.uuid)
      localStorage.setItem('region', response.region)
    }
    return response
  }
  async convertToken(token: string): Promise<Response<Auth>> {
    const response: Response<Auth> = await this.post(
      '/rest/1_0/social-auth/convert-token/',
      {
        client_id: DJANGO_OAUTH_CLIENT_ID,
        client_secret: DJANGO_OAUTH_CLIENT_SECRET,
        grant_type: 'convert_token',
        backend: 'google-oauth2',
        token,
      },
      {},
    )
    if (response.ok) {
      localStorage.setItem('accessToken', response.body.access_token)
      localStorage.setItem('refreshToken', response.body.refresh_token)
      localStorage.setItem('userUuid', response.body.user.uuid)
      localStorage.setItem('region', response.region)
    }
    return response
  }

  logout() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userUuid')
    const extId = localStorage.getItem('extId')

    if (!extId || !chrome.runtime || !navigator.userAgent.includes('Chrome'))
      return

    chrome.runtime.sendMessage(
      extId,
      {
        action: 'logout',
      },
      res => {
        if (!res.success)
          throw new Error(
            `Failed to send message to the extension ${JSON.stringify(res)}`,
          )
      },
    )
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('accessToken') !== null
  }
  async getUser(): Promise<Response<User>> {
    const userUuid = localStorage.getItem('userUuid')
    if (!userUuid) {
      this.logout()
      return Promise.reject(new Error('User not logged in'))
    }

    return this.getUserByUuid(userUuid)
  }

  sendTokenToExtension(user: User) {
    if (!user?.uuid || !navigator.userAgent.includes('Chrome')) return

    const token = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const extId = localStorage.getItem('extId')

    if (!token || !extId) return

    chrome?.runtime?.sendMessage(
      extId,
      {
        action: 'getToken',
        token,
        user: {
          uid: user.uuid,
          email: user.email,
          refreshToken,
          is_premium: user.is_premium,
          auth_type: localStorage.getItem(LOCAL_STORAGE_AUTH_TYPE),
        },
        extId,
      },
      res => {
        if (!res.success)
          throw new Error(
            `Failed to send message to the extension ${JSON.stringify(res)}`,
          )
      },
    )
  }

  async sendPasswordResetToken(
    email: string,
  ): Promise<Response<{ status: string }>> {
    return this.post('/rest/1_0/auth/send-password-reset-token/', { email }, {})
  }

  async resetPassword(
    token: string,
    password: string,
  ): Promise<Response<{ status: string }>> {
    return this.post('/rest/1_0/auth/password-reset/', { token, password }, {})
  }

  async getUserByUuid(uuid: string): Promise<Response<User>> {
    return this.get(`/rest/1_0/users/${uuid}`, {}, {})
  }

  async updateUser(uuid: string, name: string): Promise<Response<User>> {
    return this.put(
      `/rest/1_0/users/${uuid}/`,
      {
        name,
      },
      {},
    )
  }

  async deleteAccount(uuid: string): Promise<Response<null>> {
    return this.delete(`/rest/1_0/users/${uuid}/`, {})
  }

  async getMyDocuments(): Promise<Response<Paginated<Document>>> {
    return this.get('/rest/1_0/mydocuments/', {}, {})
  }

  async createDocument(
    title: string,
    content: string,
    json: object,
  ): Promise<Response<Document>> {
    return this.post(
      '/rest/1_0/mydocuments/',
      { title, content, content_formats: json },
      {},
    )
  }

  async deleteDocument(uuid: string): Promise<Response<Document>> {
    return this.delete(`/rest/1_0/mydocuments/${uuid}`, {})
  }

  async getDocument(uuid: string): Promise<Response<Document>> {
    return this.get(`/rest/1_0/mydocuments/${uuid}`, {}, {})
  }

  async updateDocument(
    uuid: string,
    title: string,
    content: string,
    json: object,
  ): Promise<Response<Document>> {
    return this.put(
      `/rest/1_0/mydocuments/${uuid}`,
      { title, content, content_formats: json },
      {},
    )
  }

  async signup(
    name: string,
    email: string,
    password: string,
  ): Promise<Response<User>> {
    return this.post(
      '/rest/1_0/users/signup/',
      {
        name,
        lastname: name,
        email,
        password,
      },
      {},
    )
  }

  async confirmAccount(activation_code: string): Promise<Response<null>> {
    return this.get(
      `/rest/1_0/users/confirm-account/${activation_code}/`,
      {},
      {},
    )
  }

  async getTypingAssistantSuggestions(
    text: string,
  ): Promise<Response<TypingAssistantSuggestions>> {
    return this.post(
      '/rest/1_0/typing_assistant/',
      {
        text,
      },
      {},
    )
  }

  async getRephraseWritingAssistantSuggestions({
    text,
    selected_text,
  }: {
    text: string
    selected_text?: string
  }): Promise<Response<RephraseWritingAssistantSuggestions>> {
    return this.post(
      '/rest/1_0/writing_assistant/rephrase_suggestions/',
      {
        text,
        selected_text,
      },
      {},
    )
  }

  async getScores({
    text,
  }: {
    text: string
  }): Promise<Response<{ scores: Scores }>> {
    return this.post('/rest/1_0/text-scoring/text-score/', { text }, {})
  }

  async getMyDictionary(): Promise<Response<Paginated<DictionaryWord>>> {
    return this.get('/rest/1_0/mydictionary/', {}, {})
  }

  async createDictionaryWord(word: string): Promise<Response<DictionaryWord>> {
    return this.post('/rest/1_0/mydictionary/', { ignore_word: word }, {})
  }

  async deleteDictionaryWord(uuid: string): Promise<Response<null>> {
    return this.delete(`/rest/1_0/mydictionary/${uuid}`, {})
  }

  async getProducts(): Promise<Response<Paginated<StripeProduct>>> {
    const region = localStorage.getItem('region')
    let options = {}
    if (region) {
      options = { headers: { 'X-Client-Region': region } }
    }

    return this.get('/rest/1_0/products/', {}, options)
  }

  async checkout(
    price_id: string,
  ): Promise<Response<{ checkout_url: string }>> {
    return this.post('/rest/1_0/checkouts/', { price_id }, {})
  }
}
