import type { FC, ReactNode } from 'react'
import { clsx } from 'clsx'
import { Button } from '../Button/Button'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'
import { Typography } from '../Typography/Typography'
import circularStrokes from '../assets/circularStrokes.svg'

interface ModalProps {
  title: string
  subtitle?: string
  primaryAction: string
  secondaryAction?: string
  onClose: () => void
  onPrimaryClick: () => void
  onCancelClick: () => void
  children: ReactNode
  className?: string
  primaryActionType?: 'primary' | 'danger' | 'premium'
  titleIcon?: IconName
}

export const InteractiveModal: FC<ModalProps> = ({
  title,
  subtitle = '',
  primaryAction,
  onClose,
  onPrimaryClick,
  onCancelClick,
  secondaryAction = 'Cancelar',
  children,
  className,
  primaryActionType = 'primary',
  titleIcon,
}) => {
  const containerClassName = clsx(
    'flex flex-col bg-white rounded-2xl border border-neutral-300 shadow-2 w-[800px]',
    className ?? null,
  )

  return (
    <div className={containerClassName}>
      {/* Modal header */}
      <div className="interactive-modal-header p-6 rounded-t-2xl relative overflow-hidden">
        <img
          alt="circular strokes"
          className="absolute -top-[130px] -left-[130px]"
          height="256px"
          src={circularStrokes}
          width="256px"
        />
        <div className="flex items-center gap-2 text-blue-correcto-700 relative">
          {titleIcon ? <Icon icon={titleIcon} size="md" /> : null}
          <Typography className="font-bold" component="h2" variant="subtitle">
            {title}
          </Typography>
        </div>
        <Typography
          className="relative font-normal"
          component="p"
          variant="body1"
        >
          {subtitle}
        </Typography>
        <button
          className="fill-neutral-600 absolute top-4 right-4"
          onClick={onClose}
          type="button"
        >
          <Icon icon="close" size="sm" />
        </button>
      </div>

      <div className="mx-6 my-8">{children}</div>
      <div className="flex justify-end gap-2 bg-blue-correcto-100 rounded-b-2xl p-6">
        <Button
          label={secondaryAction}
          onClick={onCancelClick}
          variant="tertiary"
        />
        <Button
          label={primaryAction}
          onClick={onPrimaryClick}
          variant={primaryActionType}
        />
      </div>
    </div>
  )
}
