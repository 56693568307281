import type { EditorState } from 'lexical'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  ParagraphNode,
} from 'lexical'
import type { FC } from 'react'
import { useEffect } from 'react'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import type { Match, Document } from '../../../../clients/CorrectoApiClient'
import { useUser } from '../../../../contexts'
import { SuggestionNode, RephrasingNode } from './Nodes'
import {
  FloatingMenuPlugin,
  RephrasingPlugin,
  SuggestionPlugin,
  TreeViewPlugin,
} from './Plugins'

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.focus()
  }, [editor])

  return null
}

const ParagraphNewLinePlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(
    () =>
      editor.registerNodeTransform(ParagraphNode, paragraphNode => {
        const text = paragraphNode.getTextContent()
        if (text?.split(/\r?\n/)?.length > 1) {
          paragraphNode.clear()
          text.split(/\r?\n/).forEach(p => {
            paragraphNode.insertBefore(
              $createParagraphNode().append($createTextNode(p)),
            )
          })
        }
      }),
    [editor],
  )

  return null
}

interface RichTextEditorProps {
  onChange: (content: Partial<Document>) => void
  matches: Match[]
  showTreeView: boolean
  activeSuggestion: string
  setActiveSuggestion: (index: string) => void
  suggestionReplacement?: { suggestion: string; id: string }
  onRephraseSuggestionClick: (selection: string) => void
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  onChange,
  matches,
  showTreeView,
  activeSuggestion,
  setActiveSuggestion,
  suggestionReplacement,
  onRephraseSuggestionClick,
}) => {
  const userManager = useUser()
  const isHistoryEnabled = userManager.featureFlags.includes('HISTORY')

  function changeHandler(editorState: EditorState) {
    const json = editorState.toJSON()
    const text = editorState.read(() => $getRoot().getTextContent())

    onChange({ content: text, content_formats: json })
  }

  return (
    <>
      <RichTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={
          <ContentEditable
            className="outline-none p-4 h-full overflow-y-scroll"
            spellCheck={false}
          />
        }
        placeholder={<span />}
      />
      {isHistoryEnabled ? <HistoryPlugin /> : null}
      <OnChangePlugin
        ignoreHistoryMergeTagChange={false}
        ignoreSelectionChange
        onChange={changeHandler}
      />
      <SuggestionPlugin
        activeSuggestion={activeSuggestion}
        suggestionReplacement={suggestionReplacement}
        suggestions={matches}
      />
      <RephrasingPlugin
        activeRephrasing={activeSuggestion}
        rephrasingReplacement={suggestionReplacement}
        rephrasings={matches}
      />
      <MyCustomAutoFocusPlugin />
      <ParagraphNewLinePlugin />
      <NodeEventPlugin
        eventListener={(e: Event) => {
          if (!e.target) return

          const selecteSuggestion = (
            e.target as HTMLSpanElement
          ).parentElement!.getAttribute('data-suggestion')!
          setActiveSuggestion(selecteSuggestion)
        }}
        eventType="click"
        nodeType={SuggestionNode}
      />
      <NodeEventPlugin
        eventListener={(e: Event) => {
          if (!e.target) return

          const selecteSuggestion = (
            e.target as HTMLSpanElement
          ).parentElement!.getAttribute('data-suggestion')!

          setActiveSuggestion(selecteSuggestion)
        }}
        eventType="click"
        nodeType={RephrasingNode}
      />
      <FloatingMenuPlugin
        onRephraseSuggestionClick={onRephraseSuggestionClick}
      />
      {showTreeView ? <TreeViewPlugin /> : null}
    </>
  )
}
