import type { FC, PropsWithChildren } from 'react'
import { PrivateSidebar } from '../PrivateSidebar/PrivateSidebar'
import { PrivateNavbar } from '../PrivateNavbar/PrivateNavbar'

export const PrivatePage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="h-screen flex bg-neutral-100 min-w-[1440px]">
      <PrivateSidebar />
      <div className="w-full overflow-x-scroll">
        <div className="flex flex-col h-full w-full gap-4 pt-8">
          <PrivateNavbar />
          {children}
        </div>
      </div>
    </div>
  )
}
