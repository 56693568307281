const GOOGLE_OAUTH_CLIENT_ID = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID
const APP_HOST = import.meta.env.VITE_APP_HOST as string

type Source = 'web' | 'chrome-extension'
type AuthMethod = 'LOGIN' | 'SIGNUP'

const GOOGLE_OAUTH2_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth'
const SCOPE =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'

const createHiddenInput = (name: string, value: string): HTMLInputElement => {
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', name)
  input.setAttribute('value', value)
  return input
}

const createForm = (action: string, method: string): HTMLFormElement => {
  const form = document.createElement('form')
  form.setAttribute('method', method)
  form.setAttribute('action', action)
  return form
}

export class OauthClient {
  googleOauth2Endpoint = GOOGLE_OAUTH2_ENDPOINT
  scope = SCOPE

  oauthSignIn(source: Source, authMethod: AuthMethod): void {
    const form = createForm(this.googleOauth2Endpoint, 'GET')

    const params: Record<string, string> = {
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      redirect_uri: `${APP_HOST}/oauth-google`,
      response_type: 'token',
      scope: this.scope,
      include_granted_scopes: 'true',
      state: `source=${source}&authMethod=${authMethod}`,
    }

    Object.entries(params).forEach(([key, value]) => {
      form.appendChild(createHiddenInput(key, value))
    })

    document.body.appendChild(form)
    form.submit()
  }
}
