import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useModal, Typography } from 'ui'
import { Link } from 'react-router-dom'
import { trackUserAction } from '../../utils/amplitude'

export const ModalChrome: FC = () => {
  const [modalChromeClosed, setModalChromeClosed] = useState(() => {
    return JSON.parse(localStorage.getItem('modalChromeClosed')!) as boolean
  })
  const { t } = useTranslation()
  const modalManager = useModal()

  useEffect(() => {
    if (!modalChromeClosed) {
      modalManager.openModal({
        body: (
          <div className="flex flex-col items-center gap-2">
            <Typography
              className="text-black font-normal"
              component="p"
              variant="body2"
            >
              {t(
                'Hemos mejorado la experiencia de corrección para que sea más rápida y fácil de usar. ',
              )}
            </Typography>
            <Typography
              className="text-black font-bold"
              component="p"
              variant="body2"
            >
              {t('¡Pruébala ya!')}
            </Typography>

            <div className="p-4  bg-neutral-100 text-start rounded-lg">
              <Typography
                className="text-black font-medium mb-3"
                component="p"
                variant="small"
              >
                <Trans
                  components={{
                    boldText: <b>cuatro sencillos pasos</b>,
                  }}
                  i18nKey="Te recomendamos"
                />
              </Typography>
              <ul className="list-disc text-left pl-5">
                <Typography
                  className="text-black font-medium"
                  component="li"
                  variant="small"
                >
                  <Trans
                    components={{
                      lindkDownloadChromeExt: (
                        <Link
                          className="text-blue-correcto-500"
                          onClick={handleClickLink}
                          to="/"
                        >
                          Chrome Store
                        </Link>
                      ),
                    }}
                    i18nKey="Instala extension"
                  />
                </Typography>
                <Typography
                  className="text-black font-medium"
                  component="li"
                  variant="small"
                >
                  {t(
                    '“Pinea” la extensión en la barra de herramientas para poder acceder más rápidamente.',
                  )}
                </Typography>
                <Typography
                  className="text-black font-medium"
                  component="li"
                  variant="small"
                >
                  {t(
                    'Dirígete a cualquier sitio web (Gmail, LinkedIn, WhatsApp...) y escribe tu texto.',
                  )}
                </Typography>
                <Typography
                  className="text-black font-medium"
                  component="li"
                  variant="small"
                >
                  {t(
                    'Recuerda recargar la web en la que quieras usar la extensión.',
                  )}
                </Typography>
              </ul>
            </div>
          </div>
        ),
        ilustration: 'premium',
        title: t('¡Hemos actualizado nuestra extensión para Chrome!'),
        type: 'graphic',
        primaryAction: t('Aceptar'),
        primaryActionType: 'primary',
        textAlign: 'center',
        onPrimaryClick: () => {
          handleCloseModal()
        },
        onClose: () => {
          handleCloseModal()
        },
      })
    }
  }, [modalChromeClosed])

  const handleClickLink = () => {
    handleCloseModal()
    trackUserAction({
      name: 'Download Chrome - Modal WebApp',
    })
    window.open(
      'https://chromewebstore.google.com/detail/correcto-herramienta-de-e/hllekbchnfikjpbdielihahenjmbpach',
      '_blank',
      'noopener',
    )
  }

  const handleCloseModal = () => {
    localStorage.setItem('modalChromeClosed', JSON.stringify(true))
    setModalChromeClosed(true)
  }

  return null
}
