import { useState } from 'react'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Banner } from 'ui'
import { trackUserAction } from '../../utils/amplitude'

const ALLOW_ROUTE = ['/projects', '/dictionary', '/settings']

export const BannerChrome: FC = () => {
  const [bannerClosed, setBannerClosed] = useState(() => {
    return JSON.parse(localStorage.getItem('bannerClosed')!) as boolean
  })
  const { t } = useTranslation()
  const location = useLocation()
  const shouldShowBannerChrome = ALLOW_ROUTE.includes(location.pathname)

  const handleClickBanner = () => {
    trackUserAction({
      name: 'Download Chrome - Banner',
    })
    window.open(
      'https://chromewebstore.google.com/detail/correcto-herramienta-de-e/hllekbchnfikjpbdielihahenjmbpach',
      '_blank',
      'noopener',
    )
  }

  const handleCloseBanner = () => {
    localStorage.setItem('bannerClosed', JSON.stringify(true))
    setBannerClosed(true)
  }

  return (
    <>
      {!bannerClosed && shouldShowBannerChrome ? (
        <div className="box-content flex pb-8 px-10">
          <Banner
            buttonText={t('Añadir a Chrome gratis')}
            description={t(
              'Podrás usar todas las funcionalidades en webs como Gmail, LinkedIn, Notion y muchas más!',
            )}
            ilustration="chrome-extension"
            onClick={handleClickBanner}
            onClose={handleCloseBanner}
            title={t('Añade Correcto a tu navegador Chrome')}
          />
        </div>
      ) : null}
    </>
  )
}
