import type { FC } from 'react'
import { clsx } from 'clsx'
import { Typography } from '../Typography/Typography'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'premium'
  | 'danger'
  | 'suggestion'
  | 'discard'
  | 'link'

export type ButtonSize = 'large' | 'small'

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  label: string
  leftIcon?: IconName
  rightIcon?: IconName
  className?: string
  disabled?: boolean
  onClick?: () => void
}

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'large',
  label,
  leftIcon,
  rightIcon,
  className,
  disabled,
  onClick,
}) => {
  const buttonClassName = clsx(className, {
    'inline-flex items-center justify-center font-medium transition duration-400 ease-in-out focus:outline-none':
      true,
    'px-8 py-3 rounded-3xl': size === 'large',
    'px-6 py-2 rounded-2xl button-small font-medium':
      size === 'small' &&
      variant !== 'suggestion' &&
      variant !== 'discard' &&
      variant !== 'link',
    'bg-blue-correcto-500 text-white hover:bg-blue-correcto-600 active:bg-blue-correcto-700 font-normal':
      variant === 'primary',
    'text-back button-secondary border-secondary border-2 font-normal h-[32px]':
      variant === 'secondary',
    'text-blue-correcto-500 hover:bg-purple-ia-100 hover:text-blue-correcto-700 active:text-blue-correcto-700 active:bg-purple-ia-00 font-normal':
      variant === 'tertiary',
    'text-neutral-100 button-premium font-semibold': variant === 'premium',
    'bg-red-extension-500 text-white hover:bg-red-extension-800 active:bg-red-extension-700 font-normal':
      variant === 'danger',
    'bg-green-checker-300 hover:bg-green-checker-500 active:bg-green-checker-700 text-black py-1.5 px-[11px] rounded-2xl font-normal':
      variant === 'suggestion',
    'text-neutral-700 hover:bg-neutral-200 active:bg-neutral-400 py-1.5 px-2 rounded-2xl font-normal':
      variant === 'discard',
    'over:bg-cyan-600': variant === 'link',
    'text-neutral-500': disabled,
  })

  const getTextVariant = (s: string, v: string) => {
    if (s === 'small') return 'small'
    if (v === 'premium') return 'body2'
    return 'body2'
  }

  return (
    <button
      className={buttonClassName}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {leftIcon ? (
        <span className="pr-1">
          <Icon icon={leftIcon} size={size === 'large' ? 'md' : 'sm'} />
        </span>
      ) : null}
      <Typography variant={getTextVariant(size, variant)}>{label}</Typography>
      {rightIcon ? (
        <span className="pl-1">
          <Icon icon={rightIcon} size={size === 'large' ? 'md' : 'sm'} />
        </span>
      ) : null}
    </button>
  )
}
