import type { FC, ReactNode } from 'react'
import { clsx } from 'clsx'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { Typography } from '../Typography/Typography'

interface ModalProps {
  title: string
  primaryAction: string
  onClose: () => void
  onPrimaryClick: () => void
  onCancelClick: () => void
  children: ReactNode
  className?: string
  primaryActionType?: 'primary' | 'danger' | 'premium'
}

export const SimpleModal: FC<ModalProps> = ({
  title,
  primaryAction,
  onClose,
  onPrimaryClick,
  onCancelClick,
  children,
  className,
  primaryActionType = 'primary',
}) => {
  const containerClassName = clsx(
    'flex flex-col gap-4 bg-white rounded-2xl border border-neutral-300 shadow-2 p-4 w-[410px]',
    className ?? null,
  )

  return (
    <div className={containerClassName}>
      <div className="flex justify-between items-center gap-4">
        <Typography
          className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap"
          component="h2"
          variant="body1"
        >
          {title}
        </Typography>
        <button className="fill-neutral-600" onClick={onClose} type="button">
          <Icon icon="close" size="sm" />
        </button>
      </div>
      <div className="break-words">{children}</div>
      <div className="flex justify-end gap-1">
        <Button
          label="Cancelar"
          onClick={onCancelClick}
          size="small"
          variant="tertiary"
        />
        <Button
          label={primaryAction}
          onClick={onPrimaryClick}
          size="small"
          variant={primaryActionType}
        />
      </div>
    </div>
  )
}
