import { Navigate, useLocation } from 'react-router-dom'
import type { FC, PropsWithChildren } from 'react'
import { useCorrectoApi } from '../../contexts'

export const PublicRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const correctoApi = useCorrectoApi()

  if (correctoApi.isAuthenticated()) {
    const { next }: { next: string } = location.state || {
      next: '/projects',
    }
    return <Navigate to={next} />
  }
  return <>{children}</>
}
