import * as amplitude from '@amplitude/analytics-browser'
import { useWindowSize } from '@uidotdev/usehooks'
import Cookies from 'js-cookie'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Input, Typography } from 'ui'
import { ToastType } from 'ui/src/Toast/Toast'
import {
  AuthType,
  LOCAL_STORAGE_AUTH_TYPE,
} from '../../../clients/CorrectoApiClient'
import { useCorrectoApi, useOauth, useToast, useUser } from '../../../contexts'
import { useCommonApiErrorMessage } from '../../../hooks'
import { EMAIL_REGEX_PATTERN } from '../../../utils/constants'
import {
  HeaderSubtitle,
  PageHeader,
  PublicPage,
  TermsAndConditions,
} from '../../Components'
import { trackUserAction } from '../../../utils/amplitude'

export const SignUp = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')

  const { t } = useTranslation()
  const userManager = useUser()
  const correctoApi = useCorrectoApi()
  const navigate = useNavigate()
  const location = useLocation()
  const oauth = useOauth()
  const { showToast } = useToast()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const repeatPasswordValidator = () =>
    passwordRepeat.length > 1
      ? {
          valid: password === passwordRepeat,
          message: t('Las contraseñas no coinciden'),
        }
      : {
          valid: false,
          message: t('La contraseña es obligatoria'),
        }

  const isChromeExtensionSignUp = () => {
    return location.pathname === '/sign-up-chrome-extension'
  }

  function registerHandler(): void {
    if (
      !name ||
      !email ||
      !password ||
      !passwordRepeat ||
      password !== passwordRepeat
    ) {
      return
    }

    correctoApi
      .signup(name, email, password)
      .then(response => {
        if (response.ok) {
          const values = {
            ...(Cookies.get('campaign_id') && {
              campaignId: Cookies.get('campaign_id'),
            }),
            event: 'Registro completado',
          }

          window.dataLayer?.push(values)

          if (Cookies.get('campaign_id')) {
            Cookies.remove('campaign_id')
          }

          userManager
            .login(email, password)
            .then(res => {
              if (res.ok) {
                amplitude.setUserId(res.body.user.uuid)
                localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Basic)

                if (isChromeExtensionSignUp()) {
                  correctoApi.sendTokenToExtension(res.body.user)

                  trackUserAction({
                    name: 'Sign up Email Chrome Extension',
                  })

                  window.open('https://correctoai.com/onboarding', '_self')
                } else {
                  trackUserAction({
                    name: 'Sign up Email',
                  })

                  const { next }: { next: string } = location.state || {
                    next: '/projects',
                  }

                  navigate(next)
                }
              } else {
                throw new Error(JSON.stringify(res))
              }
            })
            .catch(() => {
              localStorage.removeItem(LOCAL_STORAGE_AUTH_TYPE)
              showToast(
                ToastType.Danger,
                t('La autenticación ha fallado. Inténtalo de nuevo.'),
              )
            })
        } else {
          checkForCommonErrorMessage(
            response,
            t('Se ha producido un error al hacer el registro del usuario.'),
          )
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }

  const googleSignInHandler = () => {
    oauth.oauthSignIn(
      isChromeExtensionSignUp() ? 'chrome-extension' : 'web',
      'SIGNUP',
    )
  }

  return (
    <PublicPage>
      <PageHeader
        subtitle={
          <HeaderSubtitle
            firstLine={t(
              'Regístrate y prueba todo lo que Correcto puede ofrecerte.',
            )}
            secondLine={<SignUpSecondLine />}
          />
        }
        title={t('Registro')}
      />
      <div className="md:w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <div className="flex flex-col border-neutral-500">
          <Button
            className="p-0"
            label={t('Regístrate con Google')}
            leftIcon="google"
            onClick={googleSignInHandler}
            variant="secondary"
          />
        </div>
        <div className="flex flex-col gap-2">
          <Input
            label={t('Nombre *')}
            onChange={e => {
              setName(e.target.value)
            }}
            placeholder={t('Escribe tu nombre completo')}
            requiredMessage={t('El nombre es obligatorio')}
            value={name}
          />
          <Input
            label={t('E-mail *')}
            onChange={e => {
              setEmail(e.target.value)
            }}
            placeholder={t('Escribe tu e-mail')}
            validate={() =>
              email.length > 1
                ? {
                    valid: new RegExp(EMAIL_REGEX_PATTERN).test(email),
                    message: t('El formato del e-mail no es válido'),
                  }
                : {
                    valid: false,
                    message: t('El e-mail es obligatorio'),
                  }
            }
            value={email}
          />
          <Input
            label={t('Contraseña *')}
            mode="password"
            onChange={e => {
              setPassword(e.target.value)
            }}
            placeholder={t('Escribe tu contraseña')}
            requiredMessage={t('La contraseña es obligatoria')}
            value={password}
          />
          <Input
            label={t('Confirmar contraseña *')}
            mode="password"
            onChange={e => {
              setPasswordRepeat(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setPasswordRepeat(e.currentTarget.value)
                registerHandler()
              }
            }}
            placeholder={t('Confirma tu contraseña')}
            validate={repeatPasswordValidator}
            value={passwordRepeat}
          />
          <Button label={t('Regístrate')} onClick={registerHandler} />
          <TermsAndConditions />
        </div>
      </div>
    </PublicPage>
  )
}

const SignUpSecondLine: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowSize()

  const isSm = useMemo(() => {
    return (width && width < 768) as boolean
  }, [width])

  return (
    <>
      <Typography
        className="text-blue-correcto-700 font-normal pr-1"
        variant={isSm ? 'small' : 'body2'}
      >
        {t('Si ya tienes una cuenta,')}
      </Typography>
      <Button
        className="py-0 pl-0 pr-0 underline"
        label={t('accede al login')}
        onClick={() => {
          navigate('/login')
        }}
        size={isSm ? 'small' : undefined}
        variant="link"
      />
    </>
  )
}
