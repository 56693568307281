import { Button, Icon, ToastType, Typography } from 'ui'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PrivatePage } from '../../Components'
import { useCorrectoApi, useToast, useUser } from '../../contexts'
import type {
  StripePrice,
  StripeProduct,
} from '../../clients/CorrectoApiClient'
import { trackUserAction } from '../../utils/amplitude'

export const Premium = (): JSX.Element | null => {
  const [priceInterval, setPriceInterval] = useState<'month' | 'year'>('year')
  const [products, setProducts] = useState<StripeProduct[]>([])
  const [price, setPrice] = useState<StripePrice>()

  const { t } = useTranslation()

  const toastManager = useToast()
  const correctoApi = useCorrectoApi()
  const userManager = useUser()
  const navigate = useNavigate()

  const premiumUser = userManager.user?.is_premium

  if (premiumUser) {
    navigate('/projects')
  }

  useEffect(() => {
    correctoApi
      .getProducts()
      .then(response => {
        if (response.ok) {
          setProducts(response.body.results)
          setPrice(response.body.results[0].default_price)
        } else {
          toastManager.showToast(
            ToastType.Danger,
            t('Se ha producido un error al obtener los productos'),
          )
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }, [])

  const benefits = [
    {
      title: t('Mi Vocabulario'),
      description: t(
        'Guarda tus palabras habituales y no recibirás sugerencias sobre ellas',
      ),
    },
    {
      title: t('Sugerencias de reescritura'),
      description: <UnlimitedWithCorrectoAI />,
    },
    {
      title: t('Sugerencias de sinónimos'),
      description: <UnlimitedWithCorrectoAI />,
    },
  ]

  const radioButtonHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPriceInterval(e.currentTarget.value as 'month' | 'year')
    setPrice(
      filteredPricesByCurrency?.find(
        p => p.recurring.interval === e.currentTarget.value,
      ),
    )
    trackUserAction({
      name: 'Change price interval',
      extra: {
        interval: priceInterval,
        price: price?.unit_amount,
        priceId: price?.id,
        productName: products[0].name,
        productId: products[0].id,
      },
    })
  }

  const onCheckoutHandler = () => {
    if (!price) return
    trackUserAction({
      name: 'Select price',
      extra: {
        interval: priceInterval,
        price: price.unit_amount,
        priceId: price.id,
        productName: products[0].name,
        productId: products[0].id,
      },
    })
    correctoApi
      .checkout(price.id)
      .then(response => {
        if (response.ok) {
          window.location.href = response.body.checkout_url
          if (!response.ok) {
            toastManager.showToast(
              ToastType.Danger,
              t(
                'Se ha producido un error al redirectionar a la pasarela de pago',
              ),
            )
          }
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }
  if (!price || !products || products.length === 0) return <PrivatePage />

  const priceFormat =
    price?.currency.toLocaleLowerCase() === 'usd' ? 'en-US' : 'es-ES'
  const currency = products[0].default_price.currency

  const productPrice = price.unit_amount / 100

  const montlyPrice =
    priceInterval === 'month' ? productPrice : productPrice / 12

  const montlyPriceFormatted = new Intl.NumberFormat(priceFormat, {
    style: 'currency',
    currency,
  }).format(montlyPrice)

  const productPriceFormatted = new Intl.NumberFormat(priceFormat, {
    style: 'currency',
    currency,
  }).format(productPrice)

  const priceDetails =
    priceInterval === 'year'
      ? `(${productPriceFormatted}/${t('año')})`
      : t('(Cancela cuando quieras)')

  if (products.length === 0) return null

  const defaultCurrency: string = products[0]?.default_price.currency || ''
  const filteredPricesByCurrency = products[0]?.prices.filter(
    p => p.currency === defaultCurrency,
  )

  return (
    <PrivatePage>
      <div className="mx-4 flex flex-col justify-center items-center gap-10">
        {/* Heard */}
        <div className="flex flex-col items-center gap-4">
          <Typography
            className="text-blue-correcto-700 text-center font-bold"
            component="h1"
            variant="subtitle"
          >
            {t('Sácale todo el partido a Correcto')}
          </Typography>

          <div className="flex gap-10">
            <div className="radio-group">
              <input
                checked={priceInterval === 'month'}
                className="mr-2 outline-none cursor-auto"
                id="radio1"
                name="radio-group"
                onChange={radioButtonHandler}
                type="radio"
                value="month"
              />
              <Typography
                className="font-normal"
                component="label"
                htmlFor="radio1"
                variant="body2"
              >
                {t('Mensual')}
              </Typography>
            </div>
            <div className="radio-group">
              <input
                checked={priceInterval === 'year'}
                id="radio2"
                name="radio-group"
                onChange={radioButtonHandler}
                type="radio"
                value="year"
              />
              <Typography
                className="ml-2 font-normal"
                component="label"
                htmlFor="radio2"
                variant="body2"
              >
                {t('Anual')}
              </Typography>
            </div>
          </div>
          <div className="bg-neutral-700 rounded px-2 py-1">
            <Typography
              className="text-white font-medium"
              component="p"
              variant="small"
            >
              {t('Selecciona el pago anual y')}{' '}
              <span className="font-bold">{t('ahorra un 40%')}</span>
            </Typography>
          </div>
        </div>
        {/* Banner */}
        <div className="flex flex-col gap-12 bg-white, rounded-3xl shadow-1 px-14 py-12 w-[418px]">
          <div className="flex flex-col items-center gap-4">
            <Typography
              className="text-orange-generative-500 font-bold"
              component="h3"
              variant="h3"
            >
              👑 <strong>{t('Prémium')}</strong>
            </Typography>
            <div className="flex flex-col items-center">
              <Typography
                className="font-bold text-blue-correcto-700"
                component="p"
                variant="h3"
              >
                <strong>{montlyPriceFormatted}</strong>
              </Typography>
              <Typography className="font-normal" component="p" variant="body1">
                {t('al mes')}
              </Typography>
              <Typography className="font-normal" variant="small">
                {priceDetails}
              </Typography>
            </div>

            <Button
              className="w-full"
              label={t('Seleccionar')}
              leftIcon="crown"
              onClick={onCheckoutHandler}
              size="large"
              variant="premium"
            />
          </div>
          <div className="flex flex-col items-left gap-4">
            <Typography className="text-center font-bold" variant="body1">
              {t('Características')}
            </Typography>
            <Typography className="font-bold" variant="body2">
              {t('Todas las que ya disfrutas y además:')}
            </Typography>
            <div className="flex flex-col gap-4">
              {benefits.map((benefit, index) => (
                <div className="flex gap-4" key={index}>
                  <div className=" text-orange-generative-600 pt-1">
                    <Icon icon="circle-check" size="md" />
                  </div>
                  <div className="flex flex-col">
                    <Typography
                      className="text-black font-normal"
                      component="span"
                      variant="body1"
                    >
                      {benefit.title}
                    </Typography>
                    {typeof benefit.description === 'string' ? (
                      <Typography variant="small">
                        {benefit.description}
                      </Typography>
                    ) : (
                      benefit.description
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}

const UnlimitedWithCorrectoAI: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      <Typography className="pt-1 pr-1" variant="small">
        {t('Ilimitadas con')}
      </Typography>
      <Icon icon="correctoAI" size="sm" />
    </div>
  )
}
